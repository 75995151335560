<template>
  <section class="artists">
    <header class="artists__title-box">
      <h2 class="artists__title-box_title">
        {{ navActive.text ? navActive.text : "Artists we represent" }}
      </h2>
    </header>
    <div class="artists__nav">
      <BaseButton
        class="artists__nav-button"
        v-for="(item, index) in nav"
        :key="`nav-${index}`"
        :class="{ 'button--white': item.active, 'button--outline-white': !item.active }"
        :text="`${item.text}`"
        @click="clickNav(item.text)"
      ></BaseButton>
    </div>
    <IconLoader v-if="loading" class="artists__loader loader--white loader--lg"></IconLoader>
    <template v-else>
      <div v-if="sortedArtistsList.length > 0" class="artists__body">
        <div
          v-for="(column, index) in sortedArtistsList"
          :key="`column-${index}`"
          class="artists__body__column"
        >
          <router-link
            v-for="(item, index) in column"
            :key="`item-${index}`"
            class="artists__body__column_item"
            :to="{ path: `/artists/${item.slug}` }"
          >
            <span class="artists__body__column_name">
              {{ item.full_name }}
            </span>
          </router-link>
        </div>
      </div>
     </template>
  </section>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PublicSelect from "@/components/inputs/PublicSelect.vue";
import FilterBlock from "@/components/FilterPublic.vue";
import IconLoader from "@/components/icons/IconLoader.vue";
import { sortedColumns } from "@/helper.js";

export default {
  components: {
    BaseButton,
    PublicSelect,
    FilterBlock,
    IconLoader,
  },

  data() {
    return {
      cardData: {
        market: "",
      },
      nav: [
        {
          active: true,
          text: "Our Artists",
          key: "",
          count: 0,
        },
        {
          active: false,
          text: "Secondary Market",
          key: "secondary",
          count: 0,
        },
        {
          active: false,
          text: "All Artists",
          key: "all",
          count: 0,
        },
      ],
      navActive: {},
      loading: false,
      sortedArtistsList: [],
    };
  },
  head: {
    title: function () {
      return {
        inner: "Artists we represent browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Artists we represent browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Artists we represent browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Artists we represent browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Artists we represent browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Artists we represent browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Artists we represent browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Artists we represent browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Artists we represent browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    this.loading = true;
    this.navActive = this.nav[0];
    await this.apiGetArtistsList({ ...this.cardData });
    this.sortedArtistsList = sortedColumns(this.artists, this.getColumnsAmount); //to get columns
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Artists",
        to: "/artists",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.loading = false;
  },

  computed: {
    ...mapGetters("publicapi/artists", {
      artists: "getArtistsList",
    }),
    getColumnsAmount() {
      const width = window.innerWidth;

      if (width < 540) {
        return 1;
      } else if (width < 768) {
        return 2;
      } else if (width < 1024) {
        return 3;
      } else {
        return 4;
      }
    },
  },

  methods: {
    ...mapActions("publicapi/artists", ["apiGetArtistsList"]),
    ...mapMutations(["setBreadcrumbs"]),
    async filterTable() {
      this.loading = true;
      let data = { ...this.cardData };
      let success = await this.apiGetArtistsList(data);
      this.sortedArtistsList = sortedColumns(this.artists, this.getColumnsAmount); //to get columns
      this.loading = false;
      if (success) {
        this.cardData = data;
      }
    },
    clickNav(textButton) {
      this.nav.map(item => {
        if (item.text === textButton) {
          item.active = true;
          this.navActive = item;
          this.cardData.market = item.key;
          this.filterTable();
        } else item.active = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media screen and (max-width: $xs) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

.artists {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 19.7rem;
  padding: 0rem 10.5rem 23.5rem;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  @media screen and (max-width: $xs) {
    padding: 0rem 2rem 11rem;
    margin-top: 16rem;
  }

  &__title-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    @media screen and (max-width: $xs) {
        justify-content: start;
    }
    &_title {
      font-size: 40px;
      font-weight: 500;
      line-height: 1.2;
      @media screen and (max-width: $xs) {
        font-size: 42px;
      }
    }
  }
  &__nav {
    padding: 20px 0 0;
    display: flex;
    gap: 10px;
    @media screen and (max-width: $xs) {
      padding: 25px 0 0;
      flex-wrap: wrap;
    }
    &-button {
      border-radius: 19px;
      height: 4rem;
      padding: 0 20px;
    }
  }
  &__body {
    display: flex;
    justify-content: space-between;
    gap: 0 12px;
    width: 90%;
    margin-top: 70px;
    @media only screen and (max-width: $xs) {
      width: 100%;
      margin-top: 40px;
      display: block;
      text-align: center;
    }

    &__column {
      &_item {
        display: block;
        text-decoration: none;
        @media screen and (max-width: $xs) {
            text-align: left;
        }
      }

      &_name {
        font-size: 18px;
        line-height: 1.55;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
        
      }
    }
  }

  &__button-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 7rem;

    .button {
      min-width: 180px;
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

